import { Toaster } from "react-hot-toast";
import { Header } from "../../sections/homepage/header";
import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import BoardDisplay from "./BoardDisplay";
import { IconMail, IconWorld } from "@tabler/icons-react";

const DesignDetailPage: React.FC<PageProps> = ({ data, params }) => {

  // console.log("----------------------------------")
  // console.log("START Design detail data:")
  // console.log("----------------------------------")
  // console.log(data)
  // console.log("----------------------------------")
  // console.log("END Design detail data:")
  // console.log("----------------------------------")
  const jpgNodeId = data.airtable.data.board.localFiles[0].fields.jpgNodeId
  // console.log(data.airtable.data.description)
  const email = data.airtable.data.Email
  const website = data.airtable.data.Website
  return (
    <main className="pb-16">
      <Toaster></Toaster>
      <Header></Header>
      <div className="container mt-16">

        <h2 className='mb-4'>{data.airtable.data.Title_your_entry}</h2>
        <p className='mb-0'>by {data.airtable.data.Your_name}</p>
        <p className='mb-0'>{data.airtable.data.Company_name}</p>
        <p className="py-4"></p>
        <BoardDisplay jpgNodeId={jpgNodeId}/>

        {/* Show additional renderings */}
        <div className="my-8 border-t-2 w-full h-1"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
          <div className="flex flex-col gap-y-8 pb-8">
            {data.airtable.data.renderings?.localFiles?.map((file: any, idx: number) => {
              const img = getImage(file)
              if (!img) return <div>NO IMAGE</div>
              return <GatsbyImage key={idx} className="border-2 w-full" image={img}
                                  alt={data.airtable.data.Title_your_entry}/>
            })}
          </div>
          <div>
            <h3 className="mb-4">Description</h3>
            <p className='py-2 my-2 whitespace-pre-line'>{data.airtable.data.description}</p>
            <h3 className="mt-6 mb-4">Contact the architect</h3>
            <p className='mb-0'>{data.airtable.data.Your_name}</p>
            <p className='mb-0'>{data.airtable.data.Company_name}</p>
            {website && <p className='mb-0'>
              <IconWorld className="inline"/> <a href={"https://" + website} target="_blank"
                                                              rel="noreferrer">{website}</a>
            </p>
            }
            {email && <p><IconMail className="inline"/> <a href={`mailto:${email}`} target="_blank"
                                                           rel="noreferrer">{email}</a>
            </p>
            }
          </div>

        </div>
      </div>

    </main>
  )
}

export default DesignDetailPage

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Gatsby always queries for `id` in the collection, so you can use that
// to connect to this GraphQL query. See https://www.gatsbyjs.com/docs/reference/routing/file-system-route-api
export const query = graphql`
  query ($id: String) {
    airtable(id: { eq: $id }) {
      data {
        Title_your_entry
        Company_name
        Your_name
        Website
        Email
        board {
          localFiles {
            fields {
              jpgNodeId
            }
          }
        }
        renderings {
          localFiles {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }

          }
        }

        description:Description

      }
    }
  }
`
